import { useEffect, useState, FC } from "react";
import { pricesImport } from "../../api/rest/pricesImport";
import { CustomButton } from "../../components/common/customButton";
import { CustomInput } from "../../components/common/customInput";
import { FullContainerSpinner } from "../../components/common/fullContainerSpinner";
import { SelectList } from "../../components/common/selectList";
import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_CLUSTERS } from "../../api/graphql/cluster";
import { ICluster } from "../../@types/graphql";
import { toast } from "sonner";
import { chosenStore } from "../../store/stores.store";
import { GET_PRICES } from "../../api/graphql/prices";
import { toolbarContentVar } from "../../store/toolbar.store";
import { PricesTable } from "./table";
import {
  pricesChangedRows,
  chosenPricesCluster,
  pricesTableSearchValue,
} from "../../store/prices.store";

import Upload from "../../assets/icons/download.svg?react";
import "../../styles/pages/pricesPage.css";

const PriceTableSearch: FC = () => {
  const [loading, setLoading] = useState(false);
  const store = useReactiveVar(chosenStore);
  const searchValue = useReactiveVar(pricesTableSearchValue);
  const chosenCluster = useReactiveVar(chosenPricesCluster);

  if (!chosenCluster) return null;
  return (
    <div className="inline-flex gap-4">
      <CustomInput
        name="search"
        wrapperClassname="w-[20rem]"
        placeholder="Поиск по SKU"
        className="h-full"
        value={searchValue}
        onChange={(e) => pricesTableSearchValue(e.target.value)}
      />
      <CustomButton
        size="m"
        leftIcon={Upload}
        isLoading={loading}
        className="flex-shrink-0"
        onClick={async () => {
          try {
            setLoading(true);
            await pricesImport({
              storeId: store.id,
              clusterId: chosenCluster.id,
            });
            toast.success("Файл успешно скачен");
          } catch (e) {
            toast.error(e.message);
          } finally {
            setLoading(false);
          }
        }}
      >
        Скачать CSV
      </CustomButton>
    </div>
  );
};

const PriceActions = () => {
  const chosenCluster = useReactiveVar(chosenPricesCluster);

  const { data, loading } = useQuery<{ clusters: ICluster[] }>(GET_CLUSTERS, {
    fetchPolicy: "cache-and-network",
  });

  const clusters = data?.clusters
    ? data?.clusters?.map((i) => ({ ...i, value: i.id }))
    : [];

  return (
    <div className="prices-actions">
      <div className="prices-page-upload-file">
        <SelectList
          elements={clusters}
          active={clusters?.find((i) => i.id === chosenCluster?.id)}
          handleChange={(value) => {
            pricesChangedRows({});
            chosenPricesCluster(value as any);
          }}
          placeholder={
            loading ? "Загружаем кластеры......" : "Выберите кластер"
          }
        />
        <CustomButton
          size="m"
          leftIcon={Upload}
          onClick={() => {
            if (!chosenCluster) return toast.warning("Выберите кластер");
            toolbarContentVar({ type: "prices" });
          }}
        >
          Загрузить файл
        </CustomButton>
      </div>
      <PriceTableSearch />
    </div>
  );
};

export const PricesPage = () => {
  const storeId = useReactiveVar(chosenStore)?.id;
  const chosenCluster = useReactiveVar(chosenPricesCluster);

  const { data: pricesData, loading } = useQuery(GET_PRICES, {
    fetchPolicy: "cache-and-network",
    variables: {
      where: { storeId: { eq: storeId }, clusterId: { eq: chosenCluster?.id } },
    },
    skip: !storeId || !chosenCluster?.id,
  });

  useEffect(() => {
    return () => {
      pricesChangedRows({});
    };
  }, []);

  return (
    <div className="prices-page default-page">
      <h2 className="mainTitle">Цены на товары</h2>
      <PriceActions />
      {loading ? (
        <FullContainerSpinner />
      ) : (
        <PricesTable prices={pricesData?.prices || []} />
      )}
    </div>
  );
};
