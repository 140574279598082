import { FC, memo, useState } from "react";
import { CustomButton } from "../../components/common/customButton";
import { MenuBtns } from "../../components/common/menuBtns";
import { DefaultPopup } from "../../components/common/popup";
import { useMutation, useReactiveVar } from "@apollo/client";
import {
  DELETE_PRICE,
  GET_PRICES,
  UPDATE_PRICES,
} from "../../api/graphql/prices";

import Trash from "../../assets/icons/trash.svg?react";
import Update from "../../assets/icons/update.svg?react";
import ThreeDots from "../../assets/icons/threeDots.svg?react";
import { CellContext } from "@tanstack/react-table";
import { IPrice } from "../../@types/graphql";
import { toast } from "sonner";
import { useMounted } from "../../hooks/useMounted";
import { chosenStore } from "../../store/stores.store";
import {
  chosenPricesCluster,
  pricesChangedRows,
} from "../../store/prices.store";

export const PricesActions: FC<CellContext<IPrice, number>> = memo(
  ({ row }) => {
    const mounted = useMounted();
    const store = useReactiveVar(chosenStore);
    const editRows = useReactiveVar(pricesChangedRows);
    const cluster = useReactiveVar(chosenPricesCluster);
    const refetchQueries = [
      {
        query: GET_PRICES,
        variables: {
          where: {
            storeId: { eq: store?.id },
            clusterId: { eq: cluster?.id },
          },
        },
      },
    ];

    const [deleteMutation, { loading }] = useMutation(DELETE_PRICE, {
      refetchQueries,
    });

    const [updatePricesAllClusters] = useMutation(UPDATE_PRICES);

    const [isOpen, setIsOpen] = useState(false);
    const id = row.original.id;

    return (
      <>
        {mounted && (
          <MenuBtns
            placeholder=""
            icon={ThreeDots}
            menuItemsProps={{ anchor: "bottom end" }}
            elements={[
              {
                name: "Обновить на всех кластерах",
                value: "update",
                icon: Update,
              },
              { name: "Удалить цену", value: "delete", icon: Trash },
            ]}
            handleChange={async ({ value }) => {
              if (value === "update") {
                if (editRows[id])
                  return toast.warning(
                    "Нельзя обновить цену на кластерах если она не сохранена",
                  );

                await updatePricesAllClusters({
                  variables: {
                    updatePrices: {
                      price: +row.original.price,
                      sku: +row.original.sku,
                      storeId: store.id,
                    },
                  },
                  refetchQueries,
                });
                toast.success("SKU обновлен на всех кластерах");
              }
              if (value === "delete") return setIsOpen(true);
            }}
          />
        )}

        <DefaultPopup
          title="Удаление цены"
          description="Вы действительно хотите удалить цену?"
          handleClose={() => setIsOpen(false)}
          isOpen={isOpen}
          cancelBtnProps={{ children: "Отменить", color: "transparent" }}
          acceptBtnProps={{
            children: "Удалить",
            disabled: loading,
            onClick: async (e) => {
              try {
                e.stopPropagation();
                await deleteMutation({ variables: { updatePrice: { id } } });
                if (id in editRows) {
                  const newRows = { ...editRows };
                  delete newRows[id];
                  pricesChangedRows(newRows);
                }
                toast.success("Цена успешно удалена");
                setIsOpen(false);
              } catch (e) {
                console.log(e);
                toast.error(e.message || "ошибка");
              }
            },
          }}
        />
      </>
    );
  },
);

PricesActions.displayName = "PricesActions";
