import moment from "moment";
import { downloadFile } from "../../helpers/common";
import { axiosInstance } from "./axios";

export const pricesImport = async (params: {
  clusterId: number;
  storeId: number;
}) => {
  const result = await axiosInstance.get(`/prices/download`, {
    params,
    responseType: "blob",
  });

  downloadFile(result.data, `Выгрузка-${moment().format("DD-MM-YYYY")}.csv`);

  return { isOk: true };
};
