import { useReactiveVar } from "@apollo/client";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { IPrice } from "../../@types/graphql";
import { FC, memo, useMemo, useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { pricesTableSearchValue } from "../../store/prices.store";

import { PriceCell } from "./priceCell";
import { AdditionalCell } from "./additionalCell";
import { PriceTableHeader } from "./priceTableHeader";
import { PricesActions } from "./pricesActions";
import { CommentCell } from "./commentCell";

import "../../styles/components/tables.css";
import "../../styles/pages/pricesPage.css";

const columnHelper = createColumnHelper<IPrice>();

export const PricesTable: FC<{
  prices: IPrice[];
}> = memo(({ prices }) => {
  const searchValue = useReactiveVar(pricesTableSearchValue);
  const ref = useRef<HTMLDivElement>(null);

  const pricesColumns = useMemo(
    () => [
      columnHelper.accessor("id", {
        id: "id",
        header: "ID",
        size: 20,
      }),
      columnHelper.accessor("sku", {
        id: "sku",
        header: "SKU",
        size: 20,
      }),
      columnHelper.accessor("price", {
        id: "price",
        header: "Начальная цена",
        cell: PriceCell,
      }),
      columnHelper.display({
        id: "additional",
        header: "Изменение цены относительно количества",
        size: 400,
        cell: AdditionalCell,
      }),
      columnHelper.accessor("comment", {
        id: "comment",
        header: "Комментарий",
        size: 400,
        cell: CommentCell,
      }),
      columnHelper.display({
        id: "actions",
        cell: PricesActions,
      }),
    ],
    [],
  );

  const filteredPrices = useMemo(
    () => prices.filter((i) => i.sku.toString().includes(searchValue)),
    [prices, searchValue],
  );

  const table = useReactTable({
    data: filteredPrices,
    columns: pricesColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const { getHeaderGroups, getRowModel } = table;

  const rowVirtualizer = useVirtualizer({
    count: getRowModel().rows.length,
    estimateSize: () => 150, // approximate row height
    getScrollElement: () => ref.current,
    overscan: 7,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  const totalHeight = rowVirtualizer.getTotalSize();
  const paddingTop = virtualRows.length > 0 ? virtualRows[0].start : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalHeight - virtualRows[virtualRows.length - 1].end
      : 0;

  if (!prices?.length) return null;

  return (
    <>
      <PriceTableHeader />
      <div
        ref={ref}
        className="table-default-wrapper prices-table-wrapper"
        style={{ overflow: "auto", maxHeight: "70vh", minHeight: 280 }}
      >
        {/* Set the wrapper to handle the total virtualized height */}
        <div style={{ height: `${totalHeight}px`, position: "relative" }}>
          <table
            className="table-default price-table"
            style={{ width: "100%", position: "absolute", top: 0, left: 0 }}
          >
            <thead className="table-default-header">
              {getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      style={{ width: header.getSize() }}
                      colSpan={header.colSpan}
                      className={header.id}
                    >
                      <span className="table-default-sorting-item">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {/* Add padding top to push down the visible rows to their correct positions */}
              <tr style={{ height: `${paddingTop}px` }} />
              {virtualRows.map((virtualRow) => {
                const row = getRowModel().rows[virtualRow.index];
                return (
                  <tr key={row.id} style={{ height: `${virtualRow.size}px` }}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className={cell.column.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
              {/* Add padding bottom to ensure the virtualized scroll space */}
              <tr style={{ height: `${paddingBottom}px` }} />
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
});

PricesTable.displayName = "PricesTable";
