import { memo, useState } from "react";
import { useMutation, useReactiveVar } from "@apollo/client";
import {
  pricesChangedRows,
  chosenPricesCluster,
  IChangedRowItem,
} from "../../store/prices.store";
import { chosenStore } from "../../store/stores.store";
import { GET_PRICES, UPDATE_PRICE } from "../../api/graphql/prices";
import { toast } from "sonner";
import { ArrowPortalToolbar } from "../../components/common/portalToolbar";
import { CustomButton } from "../../components/common/customButton";

import Cross from "../../assets/icons/cross.svg?react";

export const PriceTableHeader = memo(() => {
  const [open, setOpen] = useState(false);
  const editRows = useReactiveVar(pricesChangedRows);
  const store = useReactiveVar(chosenStore);
  const cluster = useReactiveVar(chosenPricesCluster);
  const [update, { loading }] = useMutation(UPDATE_PRICE, {
    onCompleted: (data) => {
      const id = data?.updatePrice?.id;

      if (id in editRows) {
        const newRows = { ...editRows };
        delete newRows[id];
        pricesChangedRows(newRows);
      }
    },
  });

  const rows = Object.keys(editRows);

  const handleUpdate = async (item: Omit<IChangedRowItem, "sku">) => {
    try {
      await update({
        variables: {
          updatePrice: {
            id: item.id,
            price: item.price,
            markups: item.markups,
            steps: item.steps,
            comment: item.comment || undefined,
          },
        },
        refetchQueries: [
          {
            query: GET_PRICES,
            variables: {
              where: {
                storeId: { eq: store?.id },
                clusterId: { eq: cluster?.id },
              },
            },
          },
        ],
      });

      toast.success("Цена успешно обновлена");
    } catch (e) {
      toast.error(e?.message || "ошибка");
      console.log(e);
    }
  };

  const handleUpdateAll = async () => {
    let idx = 0;
    const ids = Object.keys(editRows);

    for await (const id of ids) {
      idx += 1;
      try {
        await update({
          variables: {
            updatePrice: {
              id: editRows[id].id,
              price: editRows[id].price,
              markups: editRows[id].markups,
              steps: editRows[id].steps,
              comment: editRows[id].comment || undefined,
            },
          },
          refetchQueries:
            idx === ids.length
              ? [
                  {
                    query: GET_PRICES,
                    variables: {
                      where: {
                        storeId: { eq: store?.id },
                        clusterId: { eq: cluster?.id },
                      },
                    },
                  },
                ]
              : undefined,
        });

        toast.success("Цена успешно обновлена");
      } catch (e) {
        toast.error(e.message);
        console.log(e);
      }
    }
  };

  return (
    <ArrowPortalToolbar open={open} setOpen={setOpen} show={!!rows.length}>
      {open && (
        <div className="suggestion-portal-toolbar-wrapper">
          <div className="mb-16 text-2xl flex gap-24 items-center justify-between">
            Измененные цены
            <CustomButton
              size="icon"
              color="transparent"
              onClick={() => setOpen(false)}
              leftIcon={Cross}
            />
          </div>
          <div className="flex gap-8 mb-24">
            <CustomButton
              className="w-full"
              color="transparent"
              onClick={() => pricesChangedRows({})}
            >
              Отменить все
            </CustomButton>
            <CustomButton className="w-full" onClick={() => handleUpdateAll()}>
              Сохранить все
            </CustomButton>
          </div>
          <div className="suggestion-portal-toolbar-items">
            {rows.map((i, idx) => {
              const rendererItems = editRows[i]?.steps.map((step, index) => ({
                step: step,
                percentages: editRows[i]?.markups?.slice(0, index + 1) || [],
              }));

              return (
                <div key={idx} className="suggestion-portal-toolbar-item">
                  <div>SKU: {editRows[i].sku}</div>
                  <div>Начальная цена: {editRows[i].price} ₽</div>
                  <div>Изменение цены относительно количества:</div>
                  {rendererItems.map((renderer, rendererIdx) => {
                    const priceToShow = renderer.percentages.reduce(
                      (prev, next) => Math.ceil(prev * (next / 100 + 1)),
                      +editRows[i]?.price || 0,
                    );

                    return (
                      <div key={rendererIdx}>
                        {renderer.step} штук –{" "}
                        {
                          renderer?.percentages?.[
                            renderer?.percentages?.length - 1
                          ]
                        }
                        % – {priceToShow} ₽
                      </div>
                    );
                  })}
                  {editRows[i]?.comment && (
                    <div>Комментарий: {editRows[i].comment}</div>
                  )}
                  <div className="flex gap-8 mt-8">
                    <CustomButton
                      onClick={async () => {
                        if (i in editRows) {
                          const newRows = { ...editRows };
                          delete newRows[i];
                          pricesChangedRows(newRows);
                        }
                      }}
                      color="transparent"
                      disabled={loading}
                      className="w-full"
                    >
                      Отменить
                    </CustomButton>
                    <CustomButton
                      onClick={async () =>
                        handleUpdate({
                          id: editRows[i].id,
                          price: editRows[i].price,
                          markups: editRows[i].markups,
                          steps: editRows[i].steps,
                          comment: editRows[i].comment,
                        })
                      }
                      disabled={loading}
                      className="w-full"
                    >
                      Сохранить
                    </CustomButton>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </ArrowPortalToolbar>
  );
});

PriceTableHeader.displayName = "PriceTableHeader";
